import React, {useEffect, useState} from "react";

import PostSale from "../../../../components/postSale";
import { getUrlImage } from "../../../../constants/config";

const ListSales = (props) => {
  const [margin, setMargin] = useState(null);

    const { data } = props;

    let iSale = 1;

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth > 1023) {
          setMargin(null)
        }

        if (window.innerWidth < 1024) {
          setMargin(Math.floor(3500 / window.innerWidth));
        }

        if (window.innerWidth < 850) {
          setMargin(Math.ceil(5000 / window.innerWidth));
        }

        if (window.innerWidth < 750) {
          setMargin(Math.ceil(5500 / window.innerWidth));
        }

        if (window.innerWidth < 650) {
          setMargin(Math.ceil(6500 / window.innerWidth));
        }

        if (window.innerWidth < 550) {
          setMargin(Math.ceil(7000 / window.innerWidth));
        }

        if (window.innerWidth < 450) {
          setMargin(Math.floor(7500 / window.innerWidth));
        }

        if (window.innerWidth < 350) {
          setMargin(Math.ceil(8000 / window.innerWidth));
        }
      }

      handleResize()

      window.addEventListener('resize',handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [])

    return (
        <div className="sales">
            <div className="sales__title">Акции</div>
            <div className="sales__list">
                {data && data.length > 0 && data.map((item, index) => {

                    const { Sale_card, YandexTarget } = item.attributes;
                    
                    if (!Sale_card) {
                        return;
                    }
                    let { Preview, Logo, Percent, Horizon, Vertical, Percent_symbol, Percent_Symbol_Before } = Sale_card;

                    let image = Horizon;
                    if(window.innerWidth > 1023) {
                        image = Preview;
                    }
                    if(iSale == 7){
                        iSale = 1;
                    }

                    if(window.innerWidth < 1023 && ( iSale == 1 || iSale == 4 ) ){
                        image = Vertical;
                        Percent = false;
                    }
                    if(window.innerWidth > 1023 && !Percent){
                        image = Preview;
                    }

                    if(!image || image && !image.data){
                        image = Preview;
                    }

                    if(!image || image && !image.data){
                        return;
                    }

                    iSale = iSale + 1;

                    return (
                        <div
                          className="sales__list-item"
                          key={item.id}
                          style={margin && (index === 3 || index === 9) ? {marginTop: `calc(-38vw + ${margin}px)`} : {}}
                        >
                            <PostSale  
                                id={item.id}
                                link={'#'}
                                preview={image && getUrlImage(image)} 
                                logo={getUrlImage(Logo)}
                                // price={Percent_Sales_Page && '-' + Percent_Sales_Page + (Percent_Symbol_Sales_Page && Percent_Symbol_Sales_Page || '%') || null}
                                price={window.innerWidth <= 1023 && Percent && ( Percent_Symbol_Before && Percent_Symbol_Before + ' ' || '-' ) + Percent + (Percent_symbol && Percent_symbol || '%') || null}
                                target={YandexTarget}
                                // attributes={item.attributes}
                            />
                        </div>
                    )
                })}
                {/* {[0,0,0,0,0,0].map((item, index) => {
                    return (
                        
                    )
                })}
                {[0,0,0,0,0,0].map((item, index) => {
                    return (
                        <div className="sales__list-item" key={index}>
                            <PostSale  
                                link={'#'}
                                preview={'/assets/img/bg-preview-shop.png'} 
                                logo={'/assets/img/logo-shop-3.svg'}
                                price={'500 б'}
                            />
                        </div>
                    )
                })} */}
            </div>
        </div>
    )
}

export default ListSales;
import React from "react";

import { Link } from "react-router-dom";
import { targetMetrick } from "../../constants/config";

import "./style.css";

const PostShop = (props) => {
  const {
    link,
    preview,
    logo,
    sale,
    title,
    subTitle,
    dopClass,
    isExternal,
    attributes,
  } = props;

  const saleBadgeColorMap = {
    'dark': '/front/assets/img/sale-badge-dark.svg',
    'light': '/front/assets/img/sale-badge-light.svg',
  }

  return (
    <Link
      to={isExternal && link || (attributes.Slug && "/shop/" + attributes.Slug) || link}
      target={isExternal && '_blank' || ''}
      className={`post _shop carousel-control ${(dopClass && dopClass) || ""}`}
      onClick={() => {
        targetMetrick("Партнер." + title + ".Переход");
      }}
    >
      <span className="post__preview">
        {preview && (
          <img src={preview} loading="lazy" className="post__preview_img" />
        )}
        {logo && (
          <span className="post__logo">
            <img src={logo} />
          </span>
        )}
        {sale && (
          <span
            className="post__sale"
            style={{background: `url("${saleBadgeColorMap[attributes?.Sale_card?.Sale_badge_color ?? 'light']}") no-repeat`}}>
            {sale}
           </span>
        )}
      </span>
      <span className="post__title">{title}</span>
      {subTitle && <span className="post__category">{subTitle}</span>}
    </Link>
  );
};

export default PostShop;
